#items {
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin: 25px;
    background-color: white;
    height: 300px;
    width: 650px;
    border-radius: 15px;
}

#title {
    grid-column: 1 / 3;
    grid-row: 1;
}

#description {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    max-height: 150px;
    overflow: auto;
}

#title, #description {
    column-width: 200px;
}

#cart {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: flex;
    margin: 20px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

#price {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
}

#img {
    grid-column: 3;
    grid-row: 1 / 4;
    width: 260px;
    object-fit: cover;
    border-style: solid;
    border-width: 5px;
    border-color: black;
    border-radius: 15px;
}