.Menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.MenuArea {
    margin-left: 8vmin;
    margin-right: 2vmin;
    background-color: whitesmoke;
    border-radius: 20px;
}