.OrderItemPage {
    background-image: url('https://images.unsplash.com/photo-1597434265474-b485f5cf0ee3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80');
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 1fr 9fr;
    background-size: cover;
    background-position: center;
}

.NavBar {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.Header {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

#orderItemsArea {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-left: 8vmin;
    margin-right: 2vmin;
    background-color: whitesmoke;
    border-radius: 20px;
}

#allOrderedItems {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.indItems {
    margin: 50px;
    font-size: 30px;
}

.orderButtons{
    height: 5vmin;
    font-size: 1.8vmin;
    border-radius: 10px;
    background-color: whitesmoke;
    color: black;
    border-color: black;
    border: solid;   
    margin: 10px;
}

.orderButtons:hover {
    background-color: black;
    color: white;
    border-color: grey;
}